import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-configurations-header',
  templateUrl: './configurations-header.component.html',
  styleUrls: ['./configurations-header.component.scss']
})
export class ConfigurationsHeaderComponent implements OnInit {

  showBackBtn = true;
  localStorage: any;

  constructor(public router: Router) {
  }

  ngOnInit() {
    if (JSON.parse(localStorage.getItem('TradeMen-admin-data'))) {
      this.localStorage = JSON.parse(localStorage.getItem('TradeMen-admin-data'))
    }

  }

}
