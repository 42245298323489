import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationsHeaderComponent } from './configurations-header/configurations-header.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    ConfigurationsHeaderComponent
  ],
  exports: [
    ConfigurationsHeaderComponent
  ]

})
export class ConfigurationsModule { }
